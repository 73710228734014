import { Box, Grid, Typography } from '@mui/material';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { SpreaderAvatar } from 'components/shared/SpreaderAvatar';
import { SpreaderEventBox } from 'components/shared/SpreaderEventBox';
import { StoryCard } from 'components/shared/StoryCard';
import { useGetData } from 'data/useGetData';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getSpreaderRoute } from 'routes';
import { useSpreaderRouteParams } from 'routes/useSpreaderRouteParams';

export function SpreaderPage() {
  const { spreaderId, slug: slugFromRoute } = useSpreaderRouteParams();

  const getDataState = useGetData();
  const spreader = getDataState.getSpreader(spreaderId);

  const navigate = useNavigate();

  useEffect(() => {
    if (spreader && spreader.slug !== slugFromRoute) {
      navigate(getSpreaderRoute(spreader.id, spreader.slug));
    }
  }, [navigate, spreader, slugFromRoute]);

  if (spreader === undefined) {
    return <QueryStateDisplay queryState={getDataState} />;
  }

  const stories = getDataState.getStoriesBySpreader(spreaderId);

  return (
    <Box py={2}>
      <SpreaderAvatar name={spreader.name} imageUrl={spreader.imageUrl} size={100} />

      {spreader.bio && <div dangerouslySetInnerHTML={{ __html: spreader.bio }} />}

      {stories && stories.length > 0 && (
        <>
          <Typography component="h3" variant="h6" gutterBottom>
            Stories spread by {spreader.name}
          </Typography>

          <Grid container spacing={2}>
            {stories.map(
              ({ id, storyId, storySlug, summary, imageUrl, role, quote, sourceUrl, date }) => (
                <Grid item key={id} xs={12} sm={4}>
                  <StoryCard id={storyId} slug={storySlug} summary={summary} imageUrl={imageUrl}>
                    <Box pt={1}>
                      <SpreaderEventBox
                        role={role}
                        quote={quote}
                        sourceUrl={sourceUrl}
                        date={date}
                      />
                    </Box>
                  </StoryCard>
                </Grid>
              )
            )}
          </Grid>
        </>
      )}
    </Box>
  );
}
