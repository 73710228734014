import { Outlet, Route } from 'react-router-dom';
import { SPREADERS_PATH, STORIES_PATH } from 'routes';
import { HomePage } from './HomePage/HomePage';
import { AppLayout } from './Layout/AppLayout';
import { SpreaderPage } from './Spreaders/SpreaderPage';
import { SpreadersPage } from './Spreaders/SpreadersPage';
import { AnalysisPage } from './Stories/AnalysisPage';
import { StoriesPage } from './Stories/StoriesPage';
import { StoryPage } from './Stories/StoryPage';
import { StorySpreadersPage } from './Stories/StorySpreadersPage';

export function AppRoutes() {
  return (
    <Route
      path="/"
      element={
        <AppLayout>
          <Outlet />
        </AppLayout>
      }
    >
      <Route path="" element={<HomePage />} />
      <Route path={SPREADERS_PATH}>
        <Route path="" element={<SpreadersPage />} />
        <Route path=":spreaderId/:slug?">
          <Route path="" element={<SpreaderPage />} />
        </Route>
      </Route>
      <Route path={STORIES_PATH}>
        <Route path="" element={<StoriesPage />} />
        <Route path=":storyId/:slug?">
          <Route path="" element={<StoryPage />} />
          <Route path="spreaders" element={<StorySpreadersPage />} />
          <Route path="analyses">
            <Route path=":analysisId" element={<AnalysisPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  );
}
