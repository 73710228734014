import { Box, Grid, Paper, Typography } from '@mui/material';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { IMAGE_MISSING_URL } from 'config';
import { useGetData } from 'data/useGetData';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';

export function HeaderWidget() {
  const { storyId } = useStoryRouteParams();

  const getDataState = useGetData();
  const story = getDataState.getStory(storyId);

  if (story === undefined) {
    return <QueryStateDisplay queryState={getDataState} />;
  }

  const { summary, imageAttribution } = story;
  const imageUrl = story.imageUrl ?? IMAGE_MISSING_URL;

  return (
    <Box mb={4}>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        {/* Increase the priority of the hero background image */}
        <img style={{ display: 'none' }} src={imageUrl} alt="" />

        {/* 30% opacity to dull the background image */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />

        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6 },
                pr: { md: 0 },
              }}
            >
              <Typography component="h6">The story:</Typography>
              <Typography component="h1" variant="h4" gutterBottom>
                "{summary}"
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {imageAttribution && (
        <Typography
          mt="1px"
          fontSize="x-small"
          textAlign="right"
          dangerouslySetInnerHTML={{ __html: imageAttribution }}
        ></Typography>
      )}
    </Box>
  );
}
