import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { ExternalLink } from 'components/shared/ExternalLink';
import { useState } from 'react';

interface IProps {
  label: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'outlined' | 'text' | 'contained';
}

export function ContributeButton(props: IProps) {
  const { label, size = 'medium', variant = 'outlined' } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Want to contribute?</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            We are always looking for new content, whether you believe a story to be true, a hoax,
            or somewhere in between.
          </Typography>
          <Typography gutterBottom>
            If you would like your analysis published here, please get in touch at{' '}
            <ExternalLink href="mailto:hello@whogothoaxed.com">hello@whogothoaxed.com</ExternalLink>
            .
          </Typography>
          <Typography>Thanks!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Button variant={variant} onClick={() => setOpen(true)} size={size}>
        {label}
      </Button>
    </>
  );
}
