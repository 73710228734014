import { IData } from './types';
import { useFetch } from './useFetch';

export function useGetData() {
  const { data, ...state } = useFetch<IData>('/data/data.json');

  const getAnalysesByStory = (storyId: string) =>
    data?.analyses.filter((x) => x.storyId === storyId);

  const getAnalysis = (analysisId: string) => data?.analyses.find((x) => x.id === analysisId);

  const getSpreader = (spreaderId: string) => data?.spreaders.find((x) => x.id === spreaderId);

  const getStory = (storyId: string) => data?.stories.find((x) => x.id === storyId);

  const getSpreaders = () =>
    data?.spreaders.map((spreader) => {
      const storiesCount = getStoriesBySpreader(spreader.id)?.length ?? 0;
      return { ...spreader, storiesCount };
    });

  const getSpreadersByStory = (storyId: string) =>
    data?.storySpreaderEvents
      .filter((x) => x.storyId === storyId)
      .map((storySpreaderEvent) => {
        const spreader = getSpreader(storySpreaderEvent.spreaderId);
        if (!spreader) {
          throw new Error(`Unknown spreaderId: ${storySpreaderEvent.spreaderId}.`);
        }
        return {
          ...storySpreaderEvent,
          storyId: spreader.id,
          spreaderSlug: spreader.slug,
          name: spreader.name,
          imageUrl: spreader.imageUrl,
        };
      });

  const getStoriesBySpreader = (spreaderId: string) =>
    data?.storySpreaderEvents
      .filter((x) => x.spreaderId === spreaderId)
      .map((storySpreaderEvent) => {
        const story = getStory(storySpreaderEvent.storyId);
        if (!story) {
          throw new Error(`Unknown storyId: ${storySpreaderEvent.storyId}.`);
        }
        return {
          ...storySpreaderEvent,
          storyId: story.id,
          storySlug: story.slug,
          summary: story.summary,
          imageUrl: story.imageUrl,
        };
      });

  return {
    ...state,
    data,
    getAnalysesByStory,
    getAnalysis,
    getSpreader,
    getSpreaders,
    getSpreadersByStory,
    getStoriesBySpreader,
    getStory,
  };
}
