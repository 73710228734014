export const ACCOUNT_PATH = '/account';
export const SPREADERS_PATH = '/spreaders';
export const STORIES_PATH = '/stories';

export function getSpreaderRoute(spreaderId: string, slug: string | undefined): string {
  return `${SPREADERS_PATH}/${spreaderId}/${slug}`;
}

export function getStoryRoute(storyId: string, slug: string | undefined): string {
  return `${STORIES_PATH}/${storyId}/${slug}`;
}

export function getStorySpreadersRoute(storyId: string, slug: string): string {
  return `${STORIES_PATH}/${storyId}/${slug}/spreaders`;
}

export function getAnalysisRoute(storyId: string, slug: string, analysisId: string): string {
  return `${STORIES_PATH}/${storyId}/${slug}/analyses/${analysisId}`;
}
