import { Box, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { InternalLink } from 'components/shared/InternalLink';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useGetData } from 'data/useGetData';
import { getAnalysisRoute } from 'routes';
import { useAnalysisRouteParams } from 'routes/useAnalysisRouteParams';
import { sortAnalyses } from 'utils';
import { ContributeButton } from './ContributeButton';

interface IProps {
  showAddButton?: boolean;
}

export function MoreAnalysesWidget(props: IProps) {
  const { showAddButton } = props;

  const { storyId, slug, analysisId } = useAnalysisRouteParams();

  const getDataState = useGetData();
  const story = getDataState.getStory(storyId);

  if (story === undefined) {
    return <QueryStateDisplay queryState={getDataState} />;
  }

  const analyses = getDataState.getAnalysesByStory(storyId) ?? [];

  const moreAnalyses = sortAnalyses(analyses)
    .map(({ analysis }) => analysis)
    .filter((analysis) => analysis.publishedAt && analysis.id !== analysisId);

  if (moreAnalyses.length === 0) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        More analyses of this story
      </Typography>
      <List sx={{ py: 0 }}>
        {moreAnalyses.map(({ id, title }) => (
          <ListItem key={id}>
            <InternalLink to={getAnalysisRoute(storyId, slug, id)}>{title}</InternalLink>
          </ListItem>
        ))}
        {showAddButton && (
          <ListItem sx={{ my: 2 }}>
            <ContributeButton label="Submit your own" />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
