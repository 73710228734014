import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import { brandColor } from 'config';
import { ContributeButton } from './ContributeButton';

interface IProps {
  title: string;
  label: string;
}

export function ContributeCard(props: IProps) {
  const { title, label } = props;

  return (
    <Card elevation={4}>
      <Box sx={{ height: 140, backgroundColor: brandColor.light }}>
        <AddCircleOutlineIcon htmlColor={brandColor.main} sx={{ width: '100%', height: '100%' }} />
      </Box>
      <CardContent>
        <Typography fontWeight="bold">{title}</Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <ContributeButton label={label} />
      </CardActions>
    </Card>
  );
}
