import { Typography } from '@mui/material';
import { formatDate } from 'utils';

interface IProps {
  publishedAt: string | null;
  author: string;
}

export function Byline(props: IProps) {
  const { publishedAt, author } = props;

  return (
    <Typography sx={{ fontStyle: 'italic' }}>
      {formatDate(publishedAt)} by {author}
    </Typography>
  );
}
