import { Alert, Grid, Stack } from '@mui/material';
import { GetInTouchBox } from 'components/shared/GetInTouchBox';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useGetAnalysisText } from 'data/useGetAnalysisText';
import { useGetData } from 'data/useGetData';
import { useAnalysisRouteParams } from 'routes/useAnalysisRouteParams';
import { AnalysisDisplay } from './AnalysisDisplay';
import { HeaderWidget } from './HeaderWidget';
import { MoreAnalysesWidget } from './MoreAnalysesWidget';
import { MoreStoriesWidget } from './MoreStoriesWidget';

export function AnalysisPage() {
  const { storyId, slug, analysisId } = useAnalysisRouteParams();

  const getDataState = useGetData();
  const story = getDataState.getStory(storyId);

  const getAnalysisTextState = useGetAnalysisText(analysisId);

  if (story === undefined) {
    return <QueryStateDisplay queryState={getDataState} />;
  }

  if (getAnalysisTextState.text === undefined) {
    return <QueryStateDisplay queryState={getAnalysisTextState} />;
  }

  const analysis = getDataState.getAnalysis(analysisId);
  const { text } = getAnalysisTextState;

  return (
    <article>
      <HeaderWidget />

      <Grid container spacing={5} sx={{ mt: 1 }}>
        <Grid item xs={12} md={8}>
          {analysis && text ? (
            <AnalysisDisplay storyId={storyId} slug={slug} analysis={analysis} text={text} />
          ) : (
            <Alert severity="error">Analysis not found.</Alert>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={2}>
            <MoreAnalysesWidget showAddButton />
            <MoreStoriesWidget />
            <GetInTouchBox />
          </Stack>
        </Grid>
      </Grid>
    </article>
  );
}
