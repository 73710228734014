import { Chip } from '@mui/material';
import { Verdict } from 'data/types';

interface IProps {
  value: Verdict;
  isActive?: boolean;
  onClick?: () => void;
}

export function VerdictDisplay(props: IProps) {
  const { value, isActive, onClick } = props;

  return (
    <Chip
      color="primary"
      label={value}
      variant={isActive ? 'filled' : 'outlined'}
      onClick={onClick}
    />
  );
}
