import { useEffect, useState } from 'react';

export function useFetch<T>(url: string): {
  isLoading: boolean;
  data: T | undefined;
  error: string | undefined;
} {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((response) => {
        const responseType = url.endsWith('.json') ? 'json' : 'text';
        response[responseType]().then((d) => setData(d));
      })
      .catch((error) => setError(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [url]);

  return { isLoading, data, error };
}
