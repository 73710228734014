import { Box, Stack, Typography } from '@mui/material';
import { ShareButton } from 'components/shared/ShareButton';
import { IAnalysis } from 'data/types';
import { getAnalysisRoute } from 'routes';
import { Byline } from './Byline';
import { VerdictDisplay } from './VerdictDisplay';

interface IProps {
  storyId: string;
  slug: string;
  analysis: IAnalysis;
  text: string;
}

export function AnalysisDisplay(props: IProps) {
  const { storyId, slug, analysis, text } = props;
  const { id: analysisId, author, verdict, title, publishedAt } = analysis;

  const isPublished = publishedAt !== null;

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>Verdict:</Typography>
          <VerdictDisplay value={verdict} isActive />
        </Stack>

        {isPublished && <ShareButton route={getAnalysisRoute(storyId, slug, analysisId)} />}
      </Stack>

      <Typography component="h3" variant="h4" gutterBottom sx={{ mb: 1 }}>
        {title}
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Byline publishedAt={publishedAt} author={author} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Box>
    </Box>
  );
}
