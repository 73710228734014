import { Alert, CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

type QueryState<T> = {
  isLoading: boolean;
  error?: string;
  data?: T;
};

interface IProps<T> {
  queryState: QueryState<T>;
  help?: ReactNode;
}

export function QueryStateDisplay<T>(props: IProps<T>) {
  const { queryState, help } = props;
  const { isLoading, error } = queryState;

  if (isLoading) {
    return <CircularProgress sx={{ my: 2 }} />;
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ my: 2 }}>
        {error ?? 'An error occurred.'} {help}
      </Alert>
    );
  }

  return null;
}
