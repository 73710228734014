import { Card, CardContent, Grid } from '@mui/material';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { SpreaderCard } from 'components/shared/SpreaderCard';
import { SpreaderEventBox } from 'components/shared/SpreaderEventBox';
import { useGetData } from 'data/useGetData';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { sortByDate } from 'utils';
import { ContributeButton } from './ContributeButton';
import { HeaderWidget } from './HeaderWidget';

export function StorySpreadersPage() {
  const { storyId } = useStoryRouteParams();

  const getDataState = useGetData();
  const story = getDataState.getStory(storyId);

  if (story === undefined) {
    return <QueryStateDisplay queryState={getDataState} />;
  }

  const spreaders = getDataState.getSpreadersByStory(storyId) ?? [];

  return (
    <>
      <HeaderWidget />

      <Grid container spacing={2}>
        {spreaders
          .slice()
          .sort(sortByDate)
          .map(({ id, spreaderId, spreaderSlug, name, imageUrl, role, quote, sourceUrl, date }) => (
            <Grid item key={id} xs={12} sm={4}>
              <SpreaderCard id={spreaderId} slug={spreaderSlug} name={name} imageUrl={imageUrl}>
                <SpreaderEventBox role={role} quote={quote} sourceUrl={sourceUrl} date={date} />
              </SpreaderCard>
            </Grid>
          ))}
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <ContributeButton label="Add spreader" variant="contained" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
