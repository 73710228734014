import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Chip, CircularProgress, Stack } from '@mui/material';

interface IProps {
  shareCount: number;
  loading?: boolean;
}

export function SharesDisplay(props: IProps) {
  const { shareCount, loading } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Chip
        icon={<ArrowCircleUpIcon />}
        variant="outlined"
        label={`${shareCount} share${shareCount !== 1 ? 's' : ''}`}
      />
      {loading && <CircularProgress size="1rem" />}
    </Stack>
  );
}
