import { Avatar, AvatarGroup, Grid, Stack, Typography } from '@mui/material';
import { InternalLink } from 'components/shared/InternalLink';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { useGetData } from 'data/useGetData';
import { getStoryRoute, getStorySpreadersRoute } from 'routes';
import { useCorrectSlug } from 'routes/useCorrectSlug';
import { useStoryRouteParams } from 'routes/useStoryRouteParams';
import { getInitials, sortAnalyses, sortByDate, sortByHasImageUrl } from 'utils';
import { AnalysisCard } from './AnalysisCard';
import { ContributeButton } from './ContributeButton';
import { ContributeCard } from './ContributeCard';
import { HeaderWidget } from './HeaderWidget';

export function StoryPage() {
  const { storyId, slug: slugFromRoute } = useStoryRouteParams();

  const getDataState = useGetData();
  const story = getDataState.getStory(storyId);

  useCorrectSlug(slugFromRoute, story, getStoryRoute);

  if (story === undefined) {
    return <QueryStateDisplay queryState={getDataState} />;
  }

  const analyses = getDataState.getAnalysesByStory(storyId) ?? [];
  const sortedAnalyses = sortAnalyses(analyses);

  const spreaders = getDataState.getSpreadersByStory(storyId) ?? [];

  return (
    <>
      <HeaderWidget />

      <Stack direction="row" spacing={1} alignItems="baseline" mb={1}>
        <Typography component="h3" variant="h6">
          Who spread this story?
        </Typography>
        <InternalLink to={getStorySpreadersRoute(storyId, story.slug)}>See all</InternalLink>
      </Stack>

      <Stack
        direction={{ xs: 'column', sm: 'row', md: 'row' }}
        alignItems={{ xs: 'center', sm: 'start', md: 'center' }}
        spacing={1}
        sx={{ mb: 1 }}
      >
        {spreaders.length > 0 ? (
          <AvatarGroup max={10} total={spreaders.length}>
            {spreaders
              .slice()
              .sort(sortByHasImageUrl)
              .sort(sortByDate)
              .map(({ id, name, imageUrl }) => (
                <Avatar key={id} alt={name} src={imageUrl ?? ''}>
                  {getInitials(name)}
                </Avatar>
              ))}
          </AvatarGroup>
        ) : (
          <ContributeButton label="Add spreader" size="small" variant="contained" />
        )}
      </Stack>

      <Typography component="h3" variant="h6" gutterBottom>
        Analyses
      </Typography>

      <Grid container spacing={2}>
        {sortedAnalyses?.map(({ analysis, isTopRated }) => (
          <Grid item key={analysis.id} xs={12} sm={4}>
            <AnalysisCard
              storyId={story.id}
              slug={story.slug}
              analysis={analysis}
              isTopRated={isTopRated}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={4}>
          <ContributeCard
            title={analyses.length === 0 ? `What's your take?` : 'Got a better take?'}
            label="Add your analysis"
          />
        </Grid>
      </Grid>
    </>
  );
}
