import { Box, Grid } from '@mui/material';
import { QueryStateDisplay } from 'components/shared/QueryStateDisplay';
import { StoryCard } from 'components/shared/StoryCard';
import { useGetData } from 'data/useGetData';
import { ContributeCard } from './ContributeCard';

export function StoriesPage() {
  const getDataState = useGetData();

  if (getDataState.data?.stories === undefined) {
    return <QueryStateDisplay queryState={getDataState} />;
  }

  const { stories } = getDataState.data;

  return (
    <Box py={2}>
      <Grid container spacing={2}>
        {stories.map(({ id, slug, summary, imageUrl, approvedAt }) => (
          <Grid item key={id} xs={12} sm={4}>
            <StoryCard id={id} slug={slug} summary={summary} imageUrl={imageUrl} />
          </Grid>
        ))}
        <Grid item xs={12} sm={4}>
          <ContributeCard title="Got a story to submit?" label="Submit a new story" />
        </Grid>
      </Grid>
    </Box>
  );
}
