import dayjs from 'dayjs';

import { IAnalysis } from 'data/types';

export const DAYJS_DATE_FORMAT = 'MMMM D YYYY';

export function formatDate(date: string | null): string {
  return (date ? dayjs(date) : dayjs()).format(DAYJS_DATE_FORMAT);
}

export function getInitials(name: string): string {
  return name
    .split(' ')
    .map((w) => w[0])
    .join('')
    .toLocaleUpperCase();
}

export function sortByDate(a: { date: string | null }, b: { date: string | null }): number {
  if (a.date && b.date) {
    return a.date.localeCompare(b.date);
  }

  return a.date ? -1 : 1;
}

export function sortByHasImageUrl(
  a: { imageUrl: string | null },
  b: { imageUrl: string | null }
): number {
  return a.imageUrl ? (b.imageUrl ? 0 : -1) : 1;
}

export function sortAnalyses(
  analyses: Array<IAnalysis>
): Array<{ analysis: IAnalysis; isTopRated: boolean }> {
  const topRatedAnalyses = new Array<IAnalysis>();
  const moreAnalyses = new Array<IAnalysis>();

  const analysesByUpvotes = analyses.slice().sort((a, b) => b.shareCount - a.shareCount);

  analysesByUpvotes.forEach((analysis) => {
    if (!topRatedAnalyses.some((x) => x.verdict === analysis.verdict)) {
      topRatedAnalyses.push(analysis);
    } else {
      moreAnalyses.push(analysis);
    }
  });

  return topRatedAnalyses
    .map((analysis) => ({ analysis, isTopRated: true }))
    .concat(moreAnalyses.map((analysis) => ({ analysis, isTopRated: false })));
}
